<template>
  <el-card class="demand-card">
    <el-form slot="header" class="searchFrom square-input-warp square-btn-warp" size="small"
             :inline="true"
             lable-width="80px" :model="searchForm">
      <el-form-item label="需求编号:">
        <el-input v-model="searchForm.requireId" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="提交时间:">
        <el-date-picker
          v-model="searchForm.createTimeLimit"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions">
        </el-date-picker>
      </el-form-item>
      <el-button size="small" @click="getDemand" type="primary">搜索</el-button>
    </el-form>
    <el-row class="sctp-bg-f8 sctp-pad-tb10">
      <el-col :span="1">
        <div class="sctp-tc">
          <el-checkbox @change="setAllStatus" :indeterminate="indeterminate"
                       :value="selectAll"></el-checkbox>
        </div>
      </el-col>
      <el-col :span="9">
        <div class="sctp-tc">标题</div>
      </el-col>
      <el-col :span="2">
        <div class="sctp-tc">类型</div>
      </el-col>
      <el-col :span="2">
        <div class="sctp-tc">预算</div>
      </el-col>
      <el-col :span="2">
        <div class="sctp-tc">创建者</div>
      </el-col>
      <el-col :span="2">
        <div class="sctp-tc">联系电话</div>
      </el-col>
      <el-col :span="2">
        <div class="sctp-tc">参与人数</div>
      </el-col>
      <el-col :span="2">
        <div class="sctp-tc">操作</div>
      </el-col>
    </el-row>
    <div style="min-height: 100px;" v-loading="isLoadData">
      <template v-if="demandData && demandData.length > 0">
        <template v-for="demand in demandData">
          <div :key="demand.requireid" class="demand-item">
            <div class="sctp-flex sctp-pad15 sctp-flex-aic sctp-font-12 sctp-bg-f8 sctp-info">
              <div style="margin-right: 30px;">需求编号：{{ demand.requireid }}</div>
              <div style="margin-right: 30px;">提交时间：{{ demand.createtime }}</div>
              <div style="margin-right: 30px;">截至时间：{{ demand.expectdate }}</div>
            </div>
            <el-row type="flex" align="middle" class="sctp-pad-tb15">
              <el-col :span="1">
                <div class="sctp-tc">
                  <el-checkbox v-model="demand.selected"></el-checkbox>
                </div>
              </el-col>
              <el-col :span="9">
                <div>
                  <h4 class="sctp-ellipsis">{{ demand.title }}</h4>
                  <a target="_blank" :href="'/requirement/view/' + demand.requireid">
                    <div class="sctp-ellipsis sctp-font-12 sctp-info">{{ demand.reqdesc }}</div>
                  </a>
                  <div v-if="demand.phone">联系电话：{{ demand.phone }}</div>
                  <template v-if="demand.attachment">
                    <div class="mg-t5 flex">
                      补充附件需求：
                      <a target="_blank" :href="demand.attachment" class="flex sctp-flex-aic">
                        <i class="el-icon-document mg-r5"></i>
                        {{ attachmentName(demand.attachment, demand.requireid) }}
                      </a>
                    </div>
                  </template>
                </div>
              </el-col>
              <el-col :span="2">
                <div class="sctp-tc">
                  <div>{{ demand.typeStr || '&emsp;' }}</div>
                </div>
              </el-col>
              <el-col :span="2">
                <div class="sctp-tc">
                  <div>{{ demand.budget_scope || '无预算' }}</div>
                </div>
              </el-col>
              <el-col :span="2">
                <div class="sctp-tc">
                  <div>{{ demand.createBy || '&emsp;' }}</div>
                </div>
              </el-col>
              <el-col :span="2">
                <div class="sctp-tc">
                  <div>{{ demand.phone || '&emsp;' }}</div>
                </div>
              </el-col>
              <el-col :span="2">
                <div class="sctp-tc">
                  <div>{{ demand.count || '&emsp;' }}</div>
                </div>
              </el-col>
              <el-col :span="2">
                <!-- <div class="sctp-mar-b5 sctp-tc">-->
                <!--   <el-button-->
                <!--       size="mini"-->
                <!--       @click="auditHistoryHandler(demand.requireid)"-->
                <!--   >-->
                <!--     审核历史-->
                <!--   </el-button>-->
                <!-- </div>-->
                <!-- <div class="sctp-mar-b5 sctp-tc">-->
                <!--   <el-button-->
                <!--       size="mini" type="primary"-->
                <!--       @click="deleteRequire(demand.requireid)"-->
                <!--   >-->
                <!--     删除-->
                <!--   </el-button>-->
                <!-- </div>-->
              </el-col>
            </el-row>
          </div>
        </template>
      </template>
      <template v-else>
        <div class="empty-text">暂无数据</div>
      </template>
    </div>
    <div class="sctp-pad-tb20 sctp-flex sctp-flex-fe">
      <el-pagination
        :current-page="page"
        :page-size="limit"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]"
        layout="prev, pager, next, total, sizes"
        :total="total">
      </el-pagination>
    </div>
  </el-card>
</template>

<script>
import Random from "@/resources/js/random";

export default {
  name: 'joinDemand',
  data() {
    return {
      isLoadData: false,
      searchForm: {
        requireId: null,
        createTimeLimit: null
      },
      auditStatusTag: [
        {name: '待审核', type: 'info'},
        {name: '已通过', type: 'success'},
        {name: '未通过', type: 'danger'}
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      demandData: [],
      page: 1,
      limit: 10,
      total: 0
    }
  },
  methods: {
    getDemand() {
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      if (searchForm.createTimeLimit) {
        searchForm.createStart = this.dateToString(new Date(searchForm.createTimeLimit[0]),
          'yyyy-MM-dd 00:00:00')
        searchForm.createEnd = this.dateToString(new Date(searchForm.createTimeLimit[1]),
          'yyyy-MM-dd 23:59:59')
        delete searchForm.createTimeLimit
      }
      this.isLoadData = true;
      this.$request.post({
        reqcode: '1066',
        reqdesc: '我参与的需求',
        ...searchForm,
        userId: this.user.userId,
        page: this.page,
        limit: this.limit
      }).then(res => {
        const {data = [], count = 0, types = []} = res
        data.forEach(item => {
          if (item.createtime) {
            item.createtime = this.dateToString(new Date(item.createtime), 'yyyy-MM-dd HH:mm:ss')
          }
          if (item.expectdate) {
            item.expectdate = this.dateToString(new Date(item.expectdate), 'yyyy-MM-dd HH:mm:ss')
          }
          const type = types.filter(type => type.reqtypeid === item.reqtypeid)[0]
          if (type) {
            item.typeStr = type.typename
          }
          item.selected = false
        })
        this.demandData = data
        this.total = count
      })
      .finally(() => {
        this.isLoadData = false;
      })
    },
    handleSizeChange(limit) {
      this.limit = limit
      this.getDemand()
    },
    handleCurrentChange(page) {
      this.page = page
      this.getDemand()
    },
    changeStatus(status, requireId) {
      this.$request.post({
        reqcode: '1058',
        reqdesc: '修改需求状态',
        userId: this.user.userId,
        requireId: requireId,
        status: status
      }).then(res => {
        this.getDemand()
      })
    },
    deleteRequire(requireId) {
      this.$confirm('此操作将删除该记录, 是否继续?', '提示', {
        type: 'warning'
      }).then(() => {
        this.$request.post({
          reqcode: '1059',
          reqdesc: '删除需求',
          requireId: requireId,
          userId: this.user.userId
        }).then(res => {
          this.$message.success('删除成功')
          this.getDemand()
        })
      })
    },
    setAllStatus(flag) {
      if (this.demandData) {
        this.demandData.forEach(demand => {
          demand.selected = !!flag
        })
      }
    }
  },
  computed: {
    attachmentName() {
      return function (fileName, demandId) {
        if (!fileName) {
          return '';
        }
        let suffix = fileName.substring(fileName.lastIndexOf('.'));
        fileName = fileName.substring(fileName.indexOf('-'), fileName.lastIndexOf('-')).replace(
          /-/g, '');
        return `${demandId}${fileName}${Random.instance.MathRand(4)}${suffix}`;
      }
    },
    selectAll() {
      let flag = false
      if (this.demandData && this.demandData.length > 0) {
        flag = this.demandData.every(demand => {
          return demand.selected
        })
      }
      return flag
    },
    indeterminate() {
      if (this.selectAll) {
        return false
      } else {
        let flag = false
        if (this.demandData) {
          flag = this.demandData.some(demand => {
            return demand.selected
          })
        }
        return flag
      }
    },
    auditStatus() {
      return [
        {name: '全部', value: null},
        {name: '待审核', value: 0},
        {name: '已通过', value: 1},
        {name: '未通过', value: 2}
      ]
    }
  },
  beforeMount() {
    this.getDemand()
  }
}
</script>

<style scoped>
/*@formatter:off*/
  .demand-card/deep/ .el-input {width: 110px!important;}
  .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner{width: 280px;}
  .demand-item {border: 1px solid #e5e5e5;margin-top: 15px;vertical-align: middle;}
  /*@formatter:on*/
</style>
